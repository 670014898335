import styled from 'styled-components';
import aspectRatio from '../../../styles/tools/aspectRatio';
import { gap } from '../../../styles/settings/spacing';
import {
	xsBodyLight,
	smTitleRegular,
	smBodyLight,
} from '../../atoms/Typography/index.styled';

const Article = styled.a`
	color: inherit;
	text-decoration: inherit;
	& > picture {
		padding-top: ${aspectRatio('2:1')};
		margin-bottom: ${gap[16]};
	}
`;

const Article__Title = styled(smTitleRegular)`
	color: var(--noir);
	// text-decoration: underline 2px var(--primaryAccent);
	margin-bottom: ${gap[8]};
	display: flex;
	align-items: center;


	svg {
		margin-left: 10px;
		margin-bottom: -5px;
	}
`;

const Article__Subtitle = styled(smBodyLight)`
	color: var(--noir);
	margin-bottom: ${gap[16]};

`;


const Article__Description = styled(xsBodyLight)`
	color: var(--noir);
	margin-bottom: ${gap[16]};
`;

export default {
	Article,
	Article__Title,
	Article__Subtitle,
	Article__Description,
};
